<template>
  <div
    class="marquee-container"
    :style="{
      '--duration': `${duration}s`,
      '--play-state': paused ? 'paused' : 'running',
    }"
  >
    <div class="marquee-content">
      <div ref="marquee" class="marquee">
        <div class="marquee-inner">
          <slot />
        </div>
      </div>
      <div class="marquee left-full">
        <div class="marquee-inner">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{ paused?: boolean; speed?: number }>(),
  { speed: 10 },
);
const duration = ref(30);
const marquee = ref<HTMLDivElement>();
const speed = toRef(props, "speed");
const slots = useSlots();

watchEffect(
  () => {
    if (!slots.default) return;
    duration.value = (marquee.value?.clientWidth ?? 0) / speed.value;
  },
  { flush: "pre" },
);
</script>

<style lang="scss" scoped>
@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.marquee-container {
  @apply w-full cursor-default whitespace-nowrap;
}

.marquee-content {
  @apply inline-flex w-full min-w-max whitespace-nowrap py-2;
  animation-name: marquee;
  animation-duration: var(--duration);
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-play-state: var(--play-state);
}

.marquee-inner {
  @apply inline-flex items-center justify-around;
}
</style>
