<template>
  <div
    class="relative flex justify-center gap-3 rounded-[20px] border border-navy p-6 shadow-[2px_1px_0px_2px_#16114F] md:pt-6 lg:border-none lg:pb-14 lg:pt-10 lg:shadow-none"
  >
    <Button
      class="relative z-10 w-full max-w-[221px] py-2 text-xs lg:py-[9px] lg:text-[15px]"
      :class="{
        [`bg-yellow shadow-[0px_4px_0px_#16114F]`]: selectedButton === 'recent',
      }"
      @click="selectButton('recent')"
      >Recent</Button
    >
    <Button
      class="relative z-10 w-full max-w-[221px] py-2 text-xs lg:py-[9px] lg:text-[15px]"
      :class="{
        [`bg-yellow shadow-[0px_4px_0px_#16114F]`]:
          selectedButton === 'mostImpact',
      }"
      @click="selectButton('mostImpact')"
      >Most Impact</Button
    >

    <!-- Background -->
    <div
      :class="[]"
      class="absolute left-0 top-0 h-full w-full rounded-[20px] bg-white bg-opacity-50"
    />
  </div>
</template>

<script lang="ts" setup>
import { useLandingLeaderboard } from "./useLandingLeaderboard";

const campaignId = inject<number>("campaignId");

const { selectButton, selectedButton } = useLandingLeaderboard(campaignId);
</script>
