import numberFormatter from "./useNumberFormatter";

export default function useNumberAbbreviation(number: number) {
  const abbreviatedNumber = computed(() => {
    if (number >= 1000000) {
      return numberFormatter(number / 1000000, 1, true) + "M";
    } else if (number >= 1000) {
      return numberFormatter(number / 1000, 1, true) + "K";
    } else {
      return numberFormatter(number, 0);
    }
  });

  return {
    abbreviatedNumber: abbreviatedNumber.value,
  };
}
