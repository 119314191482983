<template>
  <div class="flex items-center gap-2">
    <NuxtImg
      :src="`https://ddc-member-files.s3.us-east-2.amazonaws.com/${member.avatarPath}`"
      :width="47"
      :height="47"
      alt="avatar"
      class="h-[47px] w-[47px] rounded-full border border-navy"
    />

    <div
      class="flex h-[47px] items-center rounded-lg border border-navy px-4 text-p1 font-medium text-navy"
      :class="[color]"
    >
      {{ text }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCampaign } from "~/domains/campaigns/composables";

interface CarouselMessageProps {
  member: {
    id: number;
    campaignId: number;
    firstName: string;
    lastName: string;
    avatarPath: string;
    points: number;
    amount: number;
    createdAt: string;
  };
}

const { member } = defineProps<CarouselMessageProps>();

const impact = computed(() => {
  if (member.campaignId === 6) {
    return `${member.points}  Days Of School`;
  } else if (member.campaignId === 3) {
    return `${member.points}  Trees Planted`;
  } else if (member.campaignId === 4) {
    return `${member.points}  Lbs Of Plastic Removed`;
  } else if (member.campaignId === 5) {
    return `${member.points}  Lbs Of Plastic Removed`;
  }

  return "";
});

const { campaign } = useCampaign(member.campaignId);

const color = computed(() => {
  return campaign.value?.bgColor ?? "";
});

const text = computed(() => {
  return `${member.firstName} ${member.lastName} Just Funded ${impact.value}`;
});
</script>
