<template>
  <div
    class="p-3 md:p-6 md:pt-3 lg:rounded-3xl lg:shadow-md"
    :class="[bgColor]"
  >
    <div class="grid w-full gap-y-2">
      <div
        class="grid px-3 text-p1 font-medium text-navy"
        :class="
          selectedButton === 'mostImpact'
            ? 'grid-cols-[40px_4fr_2fr_2fr_15px] sm:grid-cols-[50px_245px_2fr_5fr_2fr]'
            : 'grid-cols-[4fr_2fr_2fr_15px] sm:grid-cols-[245px_2fr_5fr_2fr]'
        "
      >
        <div v-if="selectedButton === 'mostImpact'" class="max-w-[10px]">
          No.
        </div>
        <div>Name</div>
        <div class="items-start gap-1 md:flex">
          <span class="hidden lg:block">Impact</span> Family
        </div>
        <div>
          <span class="hidden lg:block">Total impact</span>
          <span class="block lg:hidden">Points</span>
        </div>
        <div class="hidden sm:block">
          {{ selectedButton === "mostImpact" ? "Member Since" : "Date" }}
        </div>
      </div>

      <div
        v-for="(member, i) in data"
        :key="member.memberId"
        class="relative grid cursor-pointer rounded-xl border border-navy bg-white p-[10px] font-Kontesa text-p1 font-bold text-navy shadow-[2px_2px_0px] shadow-navy hover:bg-yellow md:px-3 md:text-h5"
        :class="
          selectedButton === 'mostImpact'
            ? 'grid-cols-[40px_4fr_2fr_2fr_15px] sm:grid-cols-[50px_245px_2fr_5fr_2fr]'
            : 'grid-cols-[4fr_2fr_2fr_15px] sm:grid-cols-[245px_2fr_5fr_2fr]'
        "
        @click="
          $router.push(
            `/members/${member.firstName}${member.lastName}-${member.memberId}`,
          )
        "
      >
        <div
          v-if="selectedButton === 'mostImpact'"
          class="inline-flex items-center pr-0"
        >
          <span>#{{ i + 1 }}</span>
        </div>
        <div class="jus flex items-center gap-2">
          <div
            class="hidden w-fit shrink-0 overflow-hidden rounded-lg shadow-sm md:block"
          >
            <NuxtImg
              :src="getAvatarPath(member.avatarPath)"
              :width="28"
              :height="28"
              alt="tree icon"
              class="h-[28px] w-[28px]"
            />
          </div>
          {{ getName(member.firstName, member.lastName) }}
        </div>
        <div class="inline-flex h-full w-5 items-center gap-1 pb-2">
          <NuxtImg
            src="/images/icons/avatar.png"
            alt="person icon"
            :width="20"
            :height="20"
          />
          {{ member.referrals }}
        </div>
        <div class="flex items-center gap-1">
          <span
            class="inline-flex items-center gap-4 font-Aeonik text-xs font-bold lg:text-[15px]"
          >
            <span
              v-for="impact in member.impact.filter((i) => i.points > 0)"
              :key="impact.campaignId"
              class="hidden items-center gap-1 font-medium md:inline-flex"
            >
              <NuxtImg
                :src="getCampaign(impact.campaignId)?.icon"
                alt="small pig icon"
                :width="18"
                :height="18"
              />
              {{ useNumberAbbreviation(impact.points).abbreviatedNumber }}
              {{ getCampaign(impact.campaignId)?.impactUnit }}
            </span>
            <span class="inline-flex items-center gap-1 md:hidden">
              <NuxtImg
                src="/images/logo-icon.png"
                alt="pig icon"
                :width="24"
                :height="24"
              />
              <span>{{
                useNumberAbbreviation(member.points).abbreviatedNumber
              }}</span>
            </span>
          </span>
        </div>
        <div class="hidden sm:block">
          <span
            class="flex h-full items-center font-Aeonik text-xs font-medium opacity-50"
          >
            <p>
              {{ dateFormatter.format(new Date(member.createdAt)) }}
            </p>
          </span>
        </div>
      </div>
    </div>

    <div v-if="isPending" class="flex justify-center">
      <PigLoading />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useCampaigns } from "~/domains/campaigns/composables";
import { useLandingLeaderboard } from "./useLandingLeaderboard";
const campaignId = inject<number>("campaignId");

const { data, bgColor, isPending, selectedButton } =
  useLandingLeaderboard(campaignId);

const dateFormatter = new Intl.DateTimeFormat("en-US", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});

const getAvatarPath = (avatarPath?: string) => {
  if (!avatarPath) return "/images/emojis/pig-face.png";
  return `https://ddc-member-files.s3.us-east-2.amazonaws.com/${avatarPath}`;
};

const { data: campaigns } = useCampaigns();

const getCampaign = (campaignId: number) => {
  if (!campaigns.value) return null;
  return campaigns.value.find((campaign) => campaign.id === campaignId);
};

const getName = (firstName: string, lastName: string) => {
  return `${firstName} ${lastName}`;
};
</script>
