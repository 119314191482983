<template>
  <div
    v-if="campaign"
    class="absolute left-0 right-0 top-0 flex h-10 -translate-y-[110%] justify-between gap-20 sm:-translate-y-[90%] md:-translate-y-[140%] md:gap-40"
  >
    <div class="ml-4 flex basis-1/2 justify-evenly">
      <NuxtImg
        v-for="n in campaignIconsNmbr"
        :key="n"
        :src="campaign.icon"
        alt="book icon"
        sizes="10vw md:10vw"
        :class="[
          '-mx-[15px] h-[91px] w-[30%] object-contain md:-mx-[80px] md:w-[25%]',
          n % 2 === 0 ? 'z-1' : 'z-0',
          campaignId === 4 || campaignId === 5 ? '-mt-5' : '-mt-1 md:mt-1',
        ]"
      />
    </div>

    <div class="mr-4 flex basis-1/2 justify-evenly">
      <NuxtImg
        v-for="n in campaignIconsNmbr"
        :key="n"
        :src="campaign.icon"
        alt="book icon"
        sizes="10vw md:10vw"
        :class="[
          '-mx-[15px] h-[91px] w-[30%] object-contain md:-mx-[80px] md:w-[25%]',
          n % 2 === 0 ? 'z-1' : 'z-0',
          campaignId === 4 || campaignId === 5 ? '-mt-5' : '-mt-1 md:mt-1',
        ]"
      />
    </div>
  </div>

  <div
    v-if="campaign"
    class="absolute -top-16 left-1/2 -translate-x-1/2 md:-top-[8.5rem]"
  >
    <NuxtImg
      :src="campaign.icon"
      sizes="10vw md:100vw"
      alt="book icon"
      :class="[
        'h-[56px] w-[56px] object-contain sm:h-[80px] sm:w-[80px] md:h-[133px] md:w-[133px] ',
        campaignId === 6 ? 'mt-6 md:mt-10' : 'mt-3',
      ]"
    />
  </div>
</template>

<script lang="ts" setup>
import { useLandingLeaderboard } from "./useLandingLeaderboard";

const campaignId = inject<number>("campaignId");
let campaignIconsNmbr = 3;

if (campaignId === 3) {
  campaignIconsNmbr = 7;
}

const { campaign } = useLandingLeaderboard(campaignId);
</script>
