import { useQuery } from "@tanstack/vue-query";
import { useCampaign } from "~/domains/campaigns/composables";
import { landingQueries } from "~/domains/landing/queries";
import type { Campaign } from "~/lib/campaigns";

type LeaderboardType = "mostImpact" | "recent";

const selectedButton = ref<LeaderboardType>("mostImpact");

export const useLandingLeaderboard = (campaignId?: Campaign) => {
  const { isPending, data: leaderboardData } = useQuery({
    ...landingQueries.leaderboard(campaignId),
  });

  const { campaign } = useCampaign(campaignId ?? 9999);

  const bgColor = computed(() => {
    if (campaign.value) {
      return campaign.value.bgColor;
    }

    return "bg-purple-low";
  });

  const activeColor = computed(() => {
    if (campaign.value) {
      return campaign.value.bgColor;
    }

    return "bg-yellow";
  });

  const selectButton = (type: LeaderboardType) => {
    selectedButton.value = type;
  };

  const data = computed(() => {
    if (selectedButton.value === "mostImpact") {
      return leaderboardData.value?.mostImpact ?? [];
    }

    return leaderboardData.value?.recent ?? [];
  });

  return {
    isPending: isPending,
    data,
    campaign,
    activeColor,
    bgColor,
    selectedButton,
    selectButton,
  };
};
