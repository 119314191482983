<template>
  <div class="relative mt-2">
    <div
      class="relative mx-auto flex w-full flex-col items-center gap-20 px-4 pb-20 md:gap-40 md:pb-40"
    >
      <div class="relative w-full max-w-[1400px]">
        <LandingPageLeaderboardDecoration v-if="campaign" />
        <div
          class="relative flex flex-col gap-3 rounded-[20px] shadow-md md:gap-0 lg:rounded-[30px]"
          :class="[bgColor]"
        >
          <LandingPageLeaderboardSelector class="relative z-20 md:z-10" />
          <LandingPageLeaderboardTable
            class="relative z-10 -mt-3 rounded-b-[20px] lg:rounded-l-[30px]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useLandingLeaderboard } from "./useLandingLeaderboard";

interface LandingPageLeaderboardProps {
  campaignId?: number;
}

const props = defineProps<LandingPageLeaderboardProps>();

provide("campaignId", props.campaignId);

const { campaign, bgColor } = useLandingLeaderboard(props.campaignId);
</script>
