import { queryOptions } from "@tanstack/vue-query";
import camelize from "camelcase-keys";

export const landingQueries = {
  marquee: () =>
    queryOptions({
      queryKey: ["landing", "marquee"],
      queryFn: async () => {
        const api = useApi();
        const data = await api<MemberMarqueeMetric[]>("/v1/landing/marquee", {
          method: "GET",
        });

        return camelize(data, { deep: true });
      },
      staleTime: 1000 * 60 * 60,
    }),
  marqueeByCampaign: (campaignId: number) => {
    return queryOptions({
      queryKey: ["landing", "marquee", campaignId],
      queryFn: async () => {
        const api = useApi();
        const data = await api<MemberMarqueeMetric[]>(
          `/v1/landing/marquee/${campaignId}`,
          {
            method: "GET",
          },
        );

        return camelize(data, { deep: true });
      },
      staleTime: 1000 * 60 * 60,
    });
  },
  leaderboard: (campaignId?: number) =>
    queryOptions({
      queryKey: ["landing", "leaderboard", campaignId],
      queryFn: async () => {
        const api = useApi();
        const path = !campaignId
          ? "/v1/landing/leaderboard"
          : `/v1/landing/leaderboard/${campaignId}`;

        const data = await api<LandingLeaderboard>(path, {
          method: "GET",
        });

        return camelize(data, { deep: true });
      },
      staleTime: 1000 * 60 * 60,
    }),
};

export type MemberMarqueeMetric = {
  id: number;
  campaign_id: number;
  first_name: string;
  last_name: string;
  avatar_path: string;
  points: number;
  amount: number;
  created_at: string;
};

export type LandingLeaderboard = {
  most_impact: LandingMemberMetric[];
  recent: LandingMemberMetric[];
};

export type LandingMemberMetric = {
  member_id: number;
  first_name: string;
  last_name: string;
  points: number;
  referrals: number;
  avatar_path: string;
  created_at: string;
  impact: {
    campaign_id: number;
    amount: number;
    points: number;
  }[];
};
